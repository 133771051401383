import { t } from 'app/core/internationalization';

// Maps the ID of the nav item to a translated phrase to later pass to <Trans />
// Because the navigation content is dynamic (defined in the backend), we can not use
// the normal inline message definition method.

// see pkg/api/index.go
export function getNavTitle(navId: string | undefined) {
  // the switch cases must match the ID of the navigation item, as defined in the backend nav model
  switch (navId) {
    case 'home':
      return t('nav.home.title', 'Home');
    case 'new':
      return t('nav.new.title', 'Novo');
    case 'create':
      return t('nav.create.title', 'Criar');
    case 'create-dashboard':
      return t('nav.create-dashboard.title', 'Dashboard');
    case 'folder':
      return t('nav.create-folder.title', 'Pasta');
    case 'import':
      return t('nav.create-import.title', 'Importar Dashboard');
    case 'alert':
      return t('nav.create-alert.title', 'Criar regra de alerta');
    case 'starred':
      return t('nav.starred.title', 'Favoritos');
    case 'starred-empty':
      return t('nav.starred-empty.title', 'Seus Dashboards Favoritos vão aparecer aqui');
    case 'dashboards':
      return t('nav.dashboards.title', 'Dashboards');
    case 'dashboards/browse':
      return t('nav.dashboards.title', 'Dashboards');
    case 'dashboards/playlists':
      return t('nav.playlists.title', 'Playlists');
    case 'dashboards/snapshots':
      return t('nav.snapshots.title', 'Snapshots');
    case 'dashboards/library-panels':
      return t('nav.library-panels.title', 'Painéis de Biblioteca');
    case 'dashboards/public':
      return t('nav.public.title', 'Dashboards Públicos');
    case 'dashboards/new':
      return t('nav.new-dashboard.title', 'Novo Dashboard');
    case 'dashboards/folder/new':
      return t('nav.new-folder.title', 'Nova Pasta');
    case 'dashboards/import':
      return t('nav.create-import.title', 'Importar Dashboard');
    case 'scenes':
      return t('nav.scenes.title', 'Cenários');
    case 'explore':
      return t('nav.explore.title', 'Explorar');
    case 'alerting':
      return t('nav.alerting.title', 'Alertas');
    case 'alerting-legacy':
      return t('nav.alerting-legacy.title', 'Alertas (legado)');
    case 'alert-home':
      return t('nav.alerting-home.title', 'Home');
    case 'alert-list':
      return t('nav.alerting-list.title', 'Regras de Alerta');
    case 'receivers':
      return t('nav.alerting-receivers.title', 'Pontos de Contato');
    case 'am-routes':
      return t('nav.alerting-am-routes.title', 'Políticas de Notificação');
    case 'channels':
      return t('nav.alerting-channels.title', 'Canais de Notificação');
    case 'silences':
      return t('nav.alerting-silences.title', 'Silenciamento de Alertas');
    case 'groups':
      return t('nav.alerting-groups.title', 'Grupos');
    case 'alerting-admin':
      return t('nav.alerting-admin.title', 'Admin');
    case 'cfg':
      return t('nav.config.title', 'Administração');
    case 'datasources':
      return t('nav.datasources.title', 'Fontes de Dados');
    case 'correlations':
      return t('nav.correlations.title', 'Correlações');
    case 'users':
      return t('nav.users.title', 'Usuários');
    case 'teams':
      return t('nav.teams.title', 'Times');
    case 'plugins':
      return t('nav.plugins.title', 'Plugins');
    case 'org-settings':
      return t('nav.org-settings.title', 'Preferências Padrão');
    case 'apikeys':
      return t('nav.api-keys.title', 'Chaves de API');
    case 'serviceaccounts':
      return t('nav.service-accounts.title', 'Contas de Serviço');
    case 'admin':
      return t('nav.admin.title', 'Admin do Servidor');
    case 'support-bundles':
      return t('nav.support-bundles.title', 'Pacotes de suporte');
    case 'global-users':
      return t('nav.global-users.title', 'Usuários');
    case 'global-orgs':
      return t('nav.global-orgs.title', 'Organizações');
    case 'server-settings':
      return t('nav.server-settings.title', 'Configurações');
    case 'storage':
      return t('nav.storage.title', 'Armazenamento');
    case 'upgrading':
      return t('nav.upgrading.title', 'Propriedades e Licença');
    case 'monitoring':
      return t('nav.monitoring.title', 'Observação');
    case 'apps':
      return t('nav.apps.title', 'Aplicativos');
    case 'alerts-and-incidents':
      return t('nav.alerts-and-incidents.title', 'Alertas e Incidentes');
    case 'help':
      return t('nav.help.title', 'Ajuda');
    case 'profile/settings':
      return t('nav.profile/settings.title', 'Perfil');
    case 'profile/notifications':
      return t('nav.profile/notifications.title', 'Histórico de Notificações');
    case 'profile/password':
      return t('nav.profile/password.title', 'Alterar Senha');
    case 'sign-out':
      return t('nav.sign-out.title', 'Sair');
    case 'search':
      return t('nav.search-dashboards.title', 'Buscar Dashboards');
    default:
      return undefined;
  }
}

export function getNavSubTitle(navId: string | undefined) {
  switch (navId) {
    case 'dashboards':
      return t('nav.dashboards.subtitle', 'Crie e gerencie dashboards para visualizar seus dados');
    case 'dashboards/browse':
      return t('nav.dashboards.subtitle', 'Crie e gerencie dashboards para visualizar seus dados');
    case 'manage-folder':
      return t('nav.manage-folder.subtitle', 'Gerencie dashboards e permissões da pasta');
    case 'dashboards/playlists':
      return t('nav.playlists.subtitle', 'Grupos de dashboards que são exibidos em uma sequência');
    case 'dashboards/snapshots':
      return t(
        'nav.snapshots.subtitle',
        'Representações de dashboards interativas, pontuais e publicamente disponíveis'
      );
    case 'dashboards/library-panels':
      return t('nav.library-panels.subtitle', 'Painéis reutilizáveis que podem ser adicionados a múltiplos dashboards');
    case 'alerting':
      return t('nav.alerting.subtitle', 'Entenda problemas em seus sistemas logo após de ocorrerem');
    case 'alert-list':
      return t('nav.alerting-list.subtitle', 'Regras que determinam se um alerta será disparado');
    case 'receivers':
      return t(
        'nav.alerting-receivers.subtitle',
        'Escolha como notificar seus pontos de contato quando uma instância de alerta for disparada'
      );
    case 'am-routes':
      return t('nav.alerting-am-routes.subtitle', 'Determine como alertas são roteados para pontos de contato');
    case 'silences':
      return t('nav.alerting-silences.subtitle', 'Pare notificações de uma ou mais regras de alerta');
    case 'groups':
      return t('nav.alerting-groups.subtitle', 'Veja alertas agrupados de uma instância de Alertmanager');
    case 'datasources':
      return t('nav.datasources.subtitle', 'Adicione e configure fontes de dados');
    case 'correlations':
      return t('nav.correlations.subtitle', 'Adicione e configure correlações');
    case 'users':
      return t('nav.users.subtitle', 'Convide e atribua Cargos a usuários');
    case 'teams':
      return t('nav.teams.subtitle', 'Grupos de usuários com necessidades de permissão e dashboards em comum');
    case 'plugins':
      return t('nav.plugins.subtitle', 'Aprimore a experiência do Grafana com plugins');
    case 'org-settings':
      return t('nav.org-settings.subtitle', 'Gerencie preferências para uma organização específica');
    case 'apikeys':
      return t(
        'nav.api-keys.subtitle',
        'Crie e gerencie chaves de API que são utilizadas para interagir com APIs de HTTP do Grafana'
      );
    case 'serviceaccounts':
      return t('nav.service-accounts.subtitle', 'Use contas de serviço para executar tarefas automatizadas no Grafana');
    case 'global-users':
      return t('nav.global-users.subtitle', 'Gerencie usuários no Grafana');
    case 'global-orgs':
      return t('nav.global-orgs.subtitle', 'Instâncias isoladas do Grafana rodando no mesmo servidor');
    case 'server-settings':
      return t('nav.server-settings.subtitle', 'Visualize as configurações definidas no seu config do Grafana');
    case 'storage':
      return t('nav.storage.subtitle', 'Gerencie o armazenamento de arquivos');
    case 'support-bundles':
      return t('nav.support-bundles.subtitle', 'Baixe pacotes de suporte');
    case 'admin':
      return t(
        'nav.admin.subtitle',
        'Gerencie configurações do servidor e acesso a recursos como organizações, usuários, e licenças'
      );
    case 'apps':
      return t('nav.apps.subtitle', 'Plugins de aplicativos que aprimoram a experiência do Grafana');
    case 'monitoring':
      return t('nav.monitoring.subtitle', 'Aplicativos de monitoramento e infraestrutura');
    case 'alerts-and-incidents':
      return t('nav.alerts-and-incidents.subtitle', 'Aplicativos de alerta e gerenciamento de incidentes');
    default:
      return undefined;
  }
}
